





















































































































import {
  ConfirmarDefecto,
  ConfirmarTablonModal,
} from "@/entidades/KTB/RegistroInformacion/ConfirmarTablon";
import {
  DefectoEnviar,
  RecepcionKtb,
  RecepcionKtbDetalle,
} from "@/entidades/KTB/RegistroInformacion/RecepcionListadoQuery";
import { RecepcionGenerica } from "@/entidades/Calificacion/pallet";
import { Defecto, ObtenerSelectDefecto } from "@/entidades/Maestro/Ktb/Defecto";
import Alerta from "@/entidades/Sistema/Alerta";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { required, numeric, minValue } from "vuelidate/lib/validators";
import { Action } from "vuex-class";
import { Calificacion } from "@/entidades/MaderaVerde/KtbDespacho";
@Component({
  name: "CalificarMadera",
  components: {
    tabla: () => import("@/components/General/TablaDelete.vue"),
  },
})
export default class CalificarMadera extends Vue {
  //vuex
  @Action("changeAlerta", { namespace: "sistemaModule" })
  changeAlerta!: Function;
  @Prop({ type: Array, required: true }) listadoDefecto!: Array<Defecto>;
  @Prop({ type: Object, required: false }) items!: RecepcionGenerica;
  @Prop({ type: Object, required: false }) info!: ConfirmarTablonModal;
  @Prop({ type: String, required: true }) proceso!: string;
  @Prop({ type: String, required: true }) tipoProceso!: string;
  defectoId: number = 0;
  cantidad: number = 0;
  piezas: number = 0;
  piesRechazo: number = 0;
  largoId: number = 1;
  porcentaje:number=0;
  // listadoDefecto:Array<Defecto> =[];
  defectoEnviar: Array<DefectoEnviar> = [];
calificacionAplicada:number=0;
  headers: { text: string; value: string }[] = [
    { text: "Defecto", value: "descripcion" },
    { text: "Cantidad", value: "cantidad" },
  ];
  listadoLargo: {
    largoId: number;
    descripcion: string;
    pie: number;
    pulgada: number;
  }[] = [];
  listadoDefectoAgregado: {
    defectoId: number;
    descripcion: string;
    cantidad: number;
  }[] = [];
  @Validations()
  validations = {
    piezas: { required, numeric, minValue: minValue(1) },
  };

  async obtenerDefecto() {
    try {
      let rsp = await ObtenerSelectDefecto();
      if (rsp.isSuccess == true) {
        this.listadoDefecto = rsp.isResult;
        if (rsp.isResult != undefined) {
          rsp.isResult.forEach((c) => {
            this.listadoDefectoAgregado.push({
              defectoId: Number(c.defectoId),
              descripcion: String(c.descripcion),
              cantidad: 0,
            });
          });
        }
      }
    } catch (error) {}
  }

  setDefectosAgregados() {
    this.listadoDefecto.forEach((c) => {
      this.listadoDefectoAgregado.push({
        defectoId: Number(c.defectoId),
        descripcion: String(c.descripcion),
        cantidad: 0,
      });
    });
  }

  FormatearLargo() {
    this.items.items.forEach((c) => {
      this.largoId = c.largoId;
      this.listadoLargo.push({
        largoId: c.largoId,
        descripcion: c.largoName,
        pie: c.pie,
        pulgada: c.pulgada,
      });
    });
  }

  guardar() {
    var count: number = 0;
    if (this.piesRechazo > 0) {
      this.listadoDefectoAgregado.forEach((c) => {
        if (c.cantidad > 0) {
          count++;
          this.defectoEnviar.push({
            defectoId: c.defectoId,
            cantidad: c.cantidad,
          });
        }
      });
      if (count == 0) {
        this.changeAlerta(
          new Alerta(
            `Debe agregar al menos 1 defecto, debido a que tiene  ${this.piesRechazo} pies rechazados`,
            true,
            "danger",
            3000
          )
        );
        return;
      }
    }
    //procedemso a enviar
    let resultadoVolumen = 
      Number(this.items.volumen) -
      Number(this.items.volumen) * Number(this.calcularPorcentajeRechazo);
    this.info.porcentaje = Number(this.calcularPorcentajeRechazo);
    this.info.piesRechazado = this.piesRechazo;
    this.info.piezas = this.piezas;
    this.info.pies = this.piezasMuestreo;
    this.info.largoId = this.largoId;
    this.calificacionAplicada = this.calificacionAplicada;
    this.info.volumen =Math.ceil(resultadoVolumen);
    //Registramos los defectos
    this.defectoEnviar.forEach((t) => {
      this.info.defectos?.push(
        new ConfirmarDefecto(t.defectoId, this.largoId, Number(t.cantidad))
      );
    });
    //this.$emit('guardar',true);
  }

  guardarLocal(verificador: number) {
    try {
      var count: number = 0;
      this.defectoEnviar = [];
      if (this.piesRechazo > 0) {
        this.listadoDefectoAgregado.forEach((c) => {
          if (c.cantidad > 0) {
            count++;
            this.defectoEnviar.push({
              defectoId: c.defectoId,
              cantidad: Number(c.cantidad),
            });
          }
        });
        if (count == 0) {
          this.changeAlerta(
            new Alerta(
              `Debe agregar al menos 1 defecto, debido a que tiene  ${this.piesRechazo} pies rechazados`,
              true,
              "danger",
              3000
            )
          );
          return;
        }
      }     
      
      var volumen = this.items.volumen;
      var calificacion: Calificacion = new Calificacion(this.items.id,this.largoId,Number(this.calcularPorcentajeRechazo),this.piezas,this.piezasMuestreo, this.piesRechazo,volumen,
      this.calificacionAplicada);

      calificacion.defectos = this.defectoEnviar;
      this.$emit("calificar", calificacion, verificador);
    } catch (error) {
      //@ts-ignore
      this.changeAlerta(new Alerta(error, true, "danger", 1000));
    }
  }

  ActualizarPiesRechazados() {
    var cout: number = 0;
    this.listadoDefectoAgregado.forEach((t) => {
      cout += Number(t.cantidad);
    });
    this.piesRechazo = cout;
  }
  get piezasMuestreo() {
    var largo = this.listadoLargo.find((c) => c.largoId == this.largoId);
    if (largo != undefined) {
      return this.piezas * largo.pie;
    }
    return 0;
  }

  get calcularPorcentajeRechazo() {
    if(this.tipoProceso === "manual")
    {
      let valor = (this.piesRechazo / this.piezasMuestreo) * 100;
      return Number(valor.toFixed(1));
    }
    else 
    {
      let valor =(this.piesRechazo / this.piezasMuestreo) * 100;
    return Number(valor.toFixed(1));
    }
    
  }

  get volumenDescontar()
  {
    if(this.tipoProceso === "manual")
    {
      let valorDescontar = this.calificacionAplicada > 0 ? this.calificacionAplicada :  this.calcularPorcentajeRechazo;
      let volumenLocal = this.items.volumen;
      let descuento = volumenLocal * (valorDescontar /100)
      return Math.ceil(descuento);

    }
    else 
    {
      let valorDescontar = this.calcularPorcentajeRechazo;
      if(valorDescontar >= 10)
      {
      let volumenLocal = this.items.volumen;
      let descuento = volumenLocal * (valorDescontar /100)
      return Math.ceil(descuento);
      }
    return 0;
    }
    
  }

  get textoGuardar() {
    if (this.proceso == "ktb") {
      return "GUARDAR";
    } else {
      return "GUARDAR LOCAL";
    }
  }
  mounted() 
  {
    this.FormatearLargo();
    this.setDefectosAgregados();
    //   this.obtenerDefecto();
  }
}
